import { sagas as JournalSagas } from "../modules/journal/actions_sagas";
import { sagas as NotesSagas } from "../modules/notes/actions_sagas";
import { sagas as AuthSagas } from "../modules/auth/actions_sagas";
import { all } from 'redux-saga/effects';

const sagas = [];
sagas.push(...JournalSagas);
sagas.push(...NotesSagas);
sagas.push(...AuthSagas);

export default function* root() {
  yield all(sagas);
}
