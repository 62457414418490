import React from "react";
import { connect } from "react-redux";
import { Spinner, EditableText, Button } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import { Field, reduxForm, formValueSelector } from "redux-form";

// Draft.js
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// Actions
import {
  noteFetch,
  notesEditorSetNewNote,
  notesEditorEndSession,
  notesEditorSaveSubmit,
  noteSave,
  noteCreate,
  noteDelete,
  noteEditorEditedContent
} from "./actions_sagas";
import { Prompt } from "react-router-dom";

const jsDateFormatter = {
  // note that the native implementation of Date functions differs between browsers
  formatDate: date => date.toLocaleDateString(),
  parseDate: str => new Date(str),
  placeholder: "M/D/YYYY",
  timePrecision: 1
};

const FieldDateInput = ({ input, meta, ...props }) => {
  return (
    <DateInput
      value={input.value}
      onChange={input.onChange}
      inputProps={{ onFocus: input.onFocus }}
      {...props}
    />
  );
};

const FieldEditableText = ({ input, meta, ...props }) => {
  return (
    <EditableText multiline value={input.value} onChange={input.onChange} />
  );
};

class FieldEditor extends React.Component {
  constructor(props) {
    super(props);

    let editorState = EditorState.createEmpty();

    // If redux-form has a value
    if (props.input.value) {
      editorState = EditorState.createWithContent(
        stateFromHTML(props.input.value)
      );
    }

    // Set editorState
    this.state = {
      editorState
    };
  }

  onChange = editorState => {
    const { input } = this.props;
    input.onChange(stateToHTML(editorState.getCurrentContent()));
    this.setState({ editorState });
  };

  render() {
    return (
      <Editor
        {...this.props.input}
        onChange={() => {}}
        onEditorStateChange={this.onChange}
        editorState={this.state.editorState}
        toolbarClassName="notes-editor-editor-toolbar"
        toolbarCustomButtons={[<InsertHTMLOption />]}
        showOpenOptionOnHover={false}
      />
    );
  }
}

/**
 * Component to insert a button to replace HTML in the editor content.
 */
class InsertHTMLOption extends React.Component {
  insertHTML() {
    const { editorState, onChange } = this.props;
    const html = window.prompt("Insert HTML to replace current content:");
    if (html) {
      const newContentState = stateFromHTML(html);
      onChange(EditorState.push(editorState, newContentState, "insert-html"));
    }
  }

  render() {
    return <div onClick={e => this.insertHTML(e)}>HTML</div>;
  }
}

class NotesEditor extends React.Component {
  componentDidMount() {
    this.fetchNote(this.props);
  }

  componentWillUnmount() {
    this.props.notesEditorEndSession();
  }

  fetchNote(props) {
    if (props.match.params.id) {
      // Existing note
      this.props.noteFetch(props.match.params.id);
    } else {
      this.props.notesEditorSetNewNote();
    }
  }

  componentWillReceiveProps(newProps) {
    // if (
    //   // Editing a note
    //   this.props.match.params.id &&
    //   // No current note loaded, or
    //   ((newProps.note && !this.props.note) ||
    //     // Loading a new note
    //     (newProps.note &&
    //       this.props.note &&
    //       this.props.note.id !== newProps.note.id))
    // ) {
    //   this.fetchNote(newProps);
    // }
    // Detect new navigation and refetch if necessary
    if (newProps.match.params && newProps.match.params.id) {
      if (newProps.match.params.id !== this.props.match.params.id) {
        this.fetchNote(newProps);
      }
    }
  }

  handleDelete() {
    if (window.confirm("Are you sure you want to delete this note?")) {
      if (this.props.note.id) {
        this.props.noteDelete(this.props.note.id, this.props.history);
      }
    }
  }

  handleSave() {
    // Dispatches the Redux action that tells Redux-Form to submit.
    // Calls handleSubmit() below.
    this.props.notesEditorSaveSubmit();
  }

  handleSubmit(values) {
    // Redux-Form submit handler.
    if (this.props.note.id === null) {
      this.props.noteCreate(values, this.props.history);
    } else {
      this.props.noteSave(values);
    }
  }

  handleFormEdited(e) {
    this.props.noteEditorEditedContent();
  }

  render() {
    return (
      <div>
        {!this.props.note && (
          <div className="notes-editor-loading">
            <Spinner />
          </div>
        )}
        {this.props.note && (
          <div className="notes-editor-container">
            <Prompt
              when={this.props.hasUnsavedChanges}
              message="Unsaved changes. Are you sure you want to leave?"
            />
            <div className="notes-editor-side">
              <Button
                type="submit"
                text="Save"
                className={`pt-large notes-editor-save-button ${
                  this.props.hasUnsavedChanges ? "notes-editor-unsaved" : ""
                }`}
                intent="success"
                loading={this.props.saveActivity}
                onClick={() => this.handleSave()}
              />
            </div>
            <div className="notes-editor-main">
              <NotesEditorForm
                onSubmit={values => this.handleSubmit(values)}
                initialValues={this.props.note}
                createdAtChange={this.props.formContent.createdAtChange}
                onClickDelete={() => this.handleDelete()}
                onChange={e => this.handleFormEdited(e)}
                noteId={this.props.note.id}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

let NotesEditorForm = props => {
  const { handleSubmit, createdAtChange } = props;

  return (
    <form className="notes-editor-form" onSubmit={handleSubmit}>
      <div className="notes-show-header">
        <h1>
          <Field
            name="title"
            component={FieldEditableText}
            multiline
            placeholder="Title"
          />
        </h1>
        <h2>
          <Field
            name="subtitle"
            component={FieldEditableText}
            multiline
            placeholder="Subtitle"
          />
        </h2>
      </div>
      <div className="notes-editor-meta">
        <div className="notes-editor-meta-field">
          <Field
            name="slug"
            component="input"
            type="text"
            className="pt-input notes-editor-form-slug"
            placeholder="Slug"
          />
        </div>
        <div className="notes-editor-meta-field notes-editor-meta-field-date-input">
          <Field
            component={FieldDateInput}
            name="createdAt"
            disabled={!createdAtChange}
            {...jsDateFormatter}
          />
          <label className="pt-control pt-switch" style={{ display: "inline" }}>
            <Field component="input" type="checkbox" name="createdAtChange" />
            <span className="pt-control-indicator" />
          </label>
        </div>
        {props.noteId && (
          <div className="notes-editor-meta-field notes-editor-meta-field-delete">
            <a onClick={props.onClickDelete}>Delete</a>
          </div>
        )}
      </div>
      <div className="text-editor notes-show-content">
        <Field component={FieldEditor} name="content" />
      </div>
    </form>
  );
};

NotesEditorForm = reduxForm({
  form: "notesEditorForm"
})(NotesEditorForm);

const selector = formValueSelector("notesEditorForm");

function mapStateToProps(state) {
  const createdAtChange = selector(state, "createdAtChange");
  return {
    note: state.notes.editingNote,
    saveActivity: state.notes.editingNoteSaveActivity,
    hasUnsavedChanges: state.notes.editingNoteHasUnsavedChanges,
    formContent: {
      createdAtChange
    }
  };
}

export default connect(mapStateToProps, {
  noteFetch,
  notesEditorSetNewNote,
  notesEditorEndSession,
  notesEditorSaveSubmit,
  noteSave,
  noteCreate,
  noteDelete,
  noteEditorEditedContent
})(NotesEditor);
