import React from 'react';
import { Route, Switch } from 'react-router-dom';
import JournalIndex from './JournalIndex';
import JournalEditor from './JournalEditor';

export default class Journal extends React.Component {
  render() {
    return (
      <div className="journal">
        <Switch>
          <Route exact path={`${this.props.match.url}`} component={JournalIndex} />
          <Route exact path={`${this.props.match.url}/new`} component={JournalEditor} />
          <Route path={`${this.props.match.url}/:id`} component={JournalEditor} />
        </Switch>
      </div>
    )
  }
}