import { actionTypes } from "./actions_sagas";

const defaultState = {
  notesList: null,
  editingNote: null,
  editingNoteHasUnsavedChanges: false,
  editingNoteSaveActivity: false
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.notesListFetchSuccess:
      return {
        ...state,
        notesList: action.payload.data.notes
      };
    case actionTypes.noteFetchRequest:
      return {
        ...state,
        editingNote: null,
        editingNoteHasUnsavedChanges: false,
        editingNoteSaveActivity: false
      };
    case actionTypes.noteFetchSuccess:
      return {
        ...state,
        editingNote: {
          ...action.payload.data.note,
          createdAt: new Date(action.payload.data.note.createdAt)
        }
      };
    case actionTypes.notesEditorSetNewNote:
      return {
        ...state,
        editingNote: action.payload.note,
        editingNoteHasUnsavedChanges: false,
        editingNoteSaveActivity: false
      };
    case actionTypes.notesEditorEndSession:
      return {
        ...state,
        editingNote: null,
        editingNoteHasUnsavedChanges: false,
        editingNoteSaveActivity: false
      };
    case actionTypes.noteSaveRequest:
      return {
        ...state,
        editingNoteSaveActivity: true
      };
    case actionTypes.noteSaveSuccess:
      return {
        ...state,
        editingNoteSaveActivity: false,
        editingNoteHasUnsavedChanges: false
      };
    case actionTypes.noteEditorEditedContent:
      return {
        ...state,
        editingNoteHasUnsavedChanges: true
      };
    case actionTypes.noteCreateRequest:
      return {
        ...state,
        editingNoteHasUnsavedChanges: false
      };
    default:
      return state;
  }
}
