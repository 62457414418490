import {ApolloClient, InMemoryCache, HttpLink} from 'apollo-boost';

const csrfTag = document.querySelector("meta[name=csrf-token]");
const csrfToken = (csrfTag && csrfTag.getAttribute("content")) || null;

const linkOptions = {
  credentials: 'same-origin',
  uri: '/graphql',
};

if (csrfToken) {
  linkOptions.headers = {
    "X-CSRF-Token": csrfToken
  };
}

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink(linkOptions),
});

export default client;
