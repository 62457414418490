import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect";
import { connect } from "react-redux";
import axios from "axios";

import "./App.css";

import AppMenu from "./common/AppMenu";
import Home from "./modules/home/Home";
import Journal from "./modules/journal/Journal";
import Notes from "./modules/notes/Notes";
import Auth from "./modules/auth/Auth";

import { authLoad } from "./modules/auth/actions_sagas";

import locationHelperBuilder from "redux-auth-wrapper/history4/locationHelper";
import { Spinner } from "@blueprintjs/core";

// Set CSRF Token
let csrfToken = null;
const csrfTag = document.querySelector('meta[name="csrf-token"]');
if (csrfTag) {
  csrfToken = csrfTag.getAttribute("content");
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
}

const locationHelper = locationHelperBuilder({});

const userAuthenticated = connectedRouterRedirect({
  redirectPath: "/auth",
  authenticatedSelector: state => state.auth.signedIn === true,
  wrapperDisplayName: "UserAuthenticated"
});

const userNotAuthenticated = connectedRouterRedirect({
  redirectPath: (state, ownProps) =>
    locationHelper.getRedirectQueryParam(ownProps) || "/",
  allowRedirectBack: false,
  authenticatedSelector: state => state.auth.signedIn === false,
  wrapperDisplayName: "UserNotAuthenticated"
});

class App extends Component {
  componentDidMount() {
    // Load signed in status
    this.props.authLoad();
  }

  render() {
    return (
      <Router>
        <div className="app-root">
          {this.props.auth.loading && (
            <div className="app-loading">
              <Spinner />
            </div>
          )}
          {this.props.auth.signedIn && <AppMenu />}
          {!this.props.auth.loading && (
            <div className="app-content">
              <Route exact path="/" component={userAuthenticated(Home)} />
              <Route path="/journal" component={userAuthenticated(Journal)} />
              <Route path="/notes" component={userAuthenticated(Notes)} />
              <Route path="/auth" component={userNotAuthenticated(Auth)} />
            </div>
          )}
        </div>
      </Router>
    );
  }
}

export default connect(
  ({ auth }) => {
    return { auth };
  },
  { authLoad }
)(App);
