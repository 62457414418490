import React from "react";
import { NavLink } from "react-router-dom";
import {Icon} from "@blueprintjs/core";

export default class AppMenu extends React.Component {
  render() {
    let csrfToken = null;
    const csrfTag = document.querySelector('meta[name="csrf-token"]');
    if (csrfTag) {
      csrfToken = csrfTag.getAttribute("content");
    }
    return (
      <div className="app-menu clearfix">
        <NavLink to="/" exact activeClassName="active">
          Home
        </NavLink>
        <NavLink to="/journal" activeClassName="active">
          Journal
        </NavLink>
        <NavLink to="/notes" activeClassName="active">
          Notes
        </NavLink>
        <form action="/users/sign_out" method="post" className="signoutform">
          <input type="hidden" name="authenticity_token" value={csrfToken || ''} />
          <button type="submit" className="signout">
            Sign Out
          </button>
        </form>
      </div>
    );
  }
}
