import React from "react";
import {
  FormGroup,
  Tabs,
  Tab,
  Spinner,
  Button,
  Card,
  Elevation,
  Menu,
  MenuItem,
  Popover
} from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import Parser from "html-react-parser";
import { Prompt } from "react-router";

import {
  journalArticleFetch,
  journalEditorChangeTab,
  journalEditorSetNewArticle,
  journalEditorEditedContent,
  journalArticleSave,
  journalArticleCreate,
  journalEditorEndSession,
  journalArticleDelete
} from "./actions_sagas";

import "./styles/journal.css";

const jsDateFormatter = {
  // note that the native implementation of Date functions differs between browsers
  formatDate: date => date.toLocaleDateString(),
  parseDate: str => new Date(str),
  placeholder: "M/D/YYYY",
  timePrecision: 1
};

class JournalEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      articleID: null,
      renderedContent: null
    };
  }

  replaceContent(domNode) {
    if (domNode.name === "answerbox") {
      return (
        <div style={{ border: "1px solid #ccc", padding: "15px", marginBottom: "25px" }}>
          AnswerBox with identifier {domNode.attribs.identifier}
        </div>
      );
    }
  }

  formEdited() {
    this.props.journalEditorEditedContent();
  }

  componentDidMount() {
    this.fetchArticle(this.props);
  }

  fetchArticle(props) {
    let articleId = null;
    if (props.match.params && props.match.params.id) {
      articleId = props.match.params.id;
      this.props.journalArticleFetch(articleId);
    } else {
      this.props.journalEditorSetNewArticle();
    }
  }

  componentWillUnmount() {
    this.props.journalEditorEndSession();
  }

  componentWillReceiveProps(newProps) {
    // Detect new navigation and refetch if necessary
    if (newProps.match.params && newProps.match.params.id) {
      if (newProps.match.params.id !== this.props.match.params.id) {
        this.fetchArticle(newProps);
      }
    }

    if (
      newProps.formContent.content &&
      this.state.renderedContent === null &&
      newProps.formContent.id === this.props.match.params.id
    ) {
      this.renderContent(newProps);
    }
    // this.renderContent(newProps);
  }

  handleSubmit(values) {
    console.log(values);
    if (this.props.article.id === null) {
      this.props.journalArticleCreate(values, this.props.history);
    } else {
      this.props.journalArticleSave(values);
    }
  }

  handleClickPreview(e) {
    this.renderContent(this.props);
  }

  handleClickDelete(e) {
    if (window.confirm("Are you sure you want to delete this article?")) {
      if (this.props.article.id) {
        this.props.journalArticleDelete(
          this.props.article.id,
          this.props.history
        );
      }
    }
  }

  renderContent(props) {
    let content = props.formContent[props.tabId];
    this.setState({
      renderedContent: `<div class="journal-normal-style"><div class="journal-normal-head"><h1>${
        props.formContent.title
      }</h1><h2>${
        props.formContent.subtitle
      }</h2></div><div class="journal-normal-body">${content}</div></div>`
    });
  }

  render() {
    return (
      <div className="journal-editor-root">
        <Prompt
          when={this.props.hasUnsavedChanges}
          message="Unsaved changes. Are you sure you want to leave?"
        />
        {!this.props.article && (
          <div className="journal-editor-loading">
            <Spinner />
          </div>
        )}
        {this.props.article && (
          <EditorForm
            initialValues={this.props.article}
            onSubmit={values => this.handleSubmit(values)}
            changeTab={newTabId => this.props.journalEditorChangeTab(newTabId)}
            tabId={this.props.tabId}
            onClickPreview={e => this.handleClickPreview(e)}
            onChange={e => this.formEdited(e)}
            onClickDelete={e => this.handleClickDelete(e)}
            hasUnsavedChanges={this.props.hasUnsavedChanges}
            saveActivity={this.props.saveActivity}
            createdAtChange={this.props.formContent.createdAtChange}
            updatedAtChange={this.props.formContent.updatedAtChange}
          />
        )}
        {this.props.article && (
          <div className="journal-editor-preview">
            <Card elevation={Elevation.ONE}>
              {Parser(this.state.renderedContent || "", {
                replace: this.replaceContent
              })}
            </Card>
          </div>
        )}
      </div>
    );
  }
}

const FieldDateInput = ({ input, meta, ...props }) => {
  return (
    <DateInput
      value={input.value}
      onChange={input.onChange}
      inputProps={{ onFocus: input.onFocus }}
      {...props}
    />
  );
};

let EditorForm = props => {
  const {
    handleSubmit,
    changeTab,
    tabId,
    onClickPreview,
    hasUnsavedChanges,
    onClickDelete,
    saveActivity,
    createdAtChange,
    updatedAtChange
  } = props;

  return (
    <form className="journal-editor-form" onSubmit={handleSubmit}>
      <div className="journal-editor-form-inner">
        <div className="journal-editor-form-top">
          <div className="pt-control-group pt-vertical">
            {/*<FormGroup label="Title" labelFor="form-article-title">*/}
            <Field
              component="input"
              name="title"
              id="form-article-title"
              className="pt-input pt-large journal-editor-form-title-subtitle"
              type="text"
              placeholder="Title"
            />
            {/*</FormGroup>*/}
            {/*<FormGroup label="Subtitle" labelFor="form-article-subtitle">*/}
            <Field
              component="input"
              name="subtitle"
              id="form-article-subtitle"
              className="pt-input journal-editor-form-title-subtitle"
              type="text"
              placeholder="Subtitle"
            />
            {/*</FormGroup>*/}
            <Field
              component="input"
              name="slug"
              id="form-article-slug"
              className="pt-input journal-editor-form-slug"
              type="text"
              placeholder="Slug"
            />
          </div>
          <div className="journal-editor-form-meta">
            <div className="journal-editor-form-meta-field">
              <FormGroup
                label={
                  <div>
                    <span style={{ paddingRight: "10px" }}>Created At</span>
                    <label
                      className="pt-control pt-switch"
                      style={{ display: "inline" }}
                    >
                      <Field
                        component="input"
                        type="checkbox"
                        name="createdAtChange"
                      />
                      <span className="pt-control-indicator" />
                    </label>
                  </div>
                }
              >
                <Field
                  component={FieldDateInput}
                  name="createdAt"
                  disabled={!createdAtChange}
                  {...jsDateFormatter}
                />
              </FormGroup>
            </div>
            <div className="journal-editor-form-meta-field">
              <FormGroup
                label={
                  <div>
                    <span style={{ paddingRight: "10px" }}>Updated At</span>
                    <label
                      className="pt-control pt-switch"
                      style={{ display: "inline" }}
                    >
                      <Field
                        component="input"
                        type="checkbox"
                        name="updatedAtChange"
                      />
                      <span className="pt-control-indicator" />
                    </label>
                  </div>
                }
              >
                <Field
                  component={FieldDateInput}
                  name="updatedAt"
                  disabled={!updatedAtChange}
                  {...jsDateFormatter}
                />
              </FormGroup>
            </div>
            <div className="journal-editor-form-meta-field">
              <FormGroup label="View Type">
                <Field
                  component="input"
                  name="viewType"
                  type="text"
                  className="pt-input"
                  id="form-article-view-type"
                />
              </FormGroup>
            </div>
          </div>
          <Tabs
            animate={true}
            id="ContentTabs"
            selectedTabId={tabId}
            onChange={changeTab}
          >
            <Tab id="content" title="Content" />
            <Tab id="contentPlain" title="Content Plain" />
            <Tab id="excerpt" title="Excerpt" />
          </Tabs>
        </div>
        <div className="journal-editor-form-content">
          {tabId === "content" && (
            <Field
              component="textarea"
              name="content"
              className="pt-input pt-fill"
            />
          )}
          {tabId === "contentPlain" && (
            <Field
              component="textarea"
              name="contentPlain"
              className="pt-input pt-fill"
            />
          )}
          {tabId === "excerpt" && (
            <Field
              component="textarea"
              name="excerpt"
              className="pt-input pt-fill"
            />
          )}
        </div>
        <div className="journal-editor-form-actions">
          <div className="journal-editor-form-actions-main">
            <Button
              type="submit"
              icon="floppy-disk"
              text="Save"
              className="pt-large"
              intent="success"
              loading={saveActivity}
            />
            &nbsp;
            <Popover
              content={
                <Menu>
                  <MenuItem text={"Delete article"} onClick={onClickDelete} />
                </Menu>
              }
              target={<Button icon="cog" className="pt-minimal" />}
            />
          </div>
          {hasUnsavedChanges && <span>Unsaved changes.</span>}
          <Button
            rightIcon="arrow-right"
            text="Preview"
            className="pt-large"
            onClick={onClickPreview}
          />
        </div>
      </div>
    </form>
  );
};

EditorForm = reduxForm({
  form: "editorForm"
})(EditorForm);

const selector = formValueSelector("editorForm");

function mapStateToProps(state) {
  const {
    id,
    title,
    subtitle,
    content,
    contentPlain,
    excerpt,
    createdAtChange,
    updatedAtChange
  } = selector(
    state,
    "id",
    "title",
    "subtitle",
    "content",
    "contentPlain",
    "excerpt",
    "createdAtChange",
    "updatedAtChange"
  );
  return {
    article: state.journal.editingArticle,
    tabId: state.journal.editingArticleTab,
    saveActivity: state.journal.editingArticleSaveActivity,
    formContent: {
      id,
      title,
      subtitle,
      content,
      contentPlain,
      excerpt,
      createdAtChange,
      updatedAtChange
    },
    hasUnsavedChanges: state.journal.editingArticleHasUnsavedChanges
  };
}

export default connect(mapStateToProps, {
  journalArticleFetch,
  journalEditorChangeTab,
  journalEditorSetNewArticle,
  journalEditorEditedContent,
  journalArticleSave,
  journalArticleCreate,
  journalEditorEndSession,
  journalArticleDelete
})(JournalEditor);
