import { call, put, fork, takeLatest } from "redux-saga/effects";
import axios from "axios";
import client from "../../common/apollo";
import {gql} from 'apollo-boost';

export const actionTypes = {
  journalArticleFetchRequest: "JOURNAL_ARTICLE_FETCH_REQUEST",
  journalArticleFetchSuccess: "JOURNAL_ARTICLE_FETCH_SUCCESS",

  journalArticleSaveRequest: "JOURNAL_ARTICLE_SAVE_REQUEST",
  journalArticleSaveSuccess: "JOURNAL_ARTICLE_SAVE_SUCCESS",

  journalArticleCreateRequest: "JOURNAL_ARTICLE_CREATE_REQUEST",
  journalArticleCreateSuccess: "JOURNAL_ARTICLE_CREATE_SUCCESS",

  journalEditorSetNewArticle: "JOURNAL_EDITOR_SET_NEW_ARTICLE",

  journalEditorChangeTab: "JOURNAL_EDITOR_CHANGE_TAB",

  journalEditorEditedContent: "JOURNAL_EDITOR_EDITED_CONTENT",

  journalEditorEndSession: "JOURNAL_EDITOR_END_SESSION",

  journalArticleDeleteRequest: "JOURNAL_ARTICLE_DELETE_REQUEST",
  journalArticleDeleteSuccess: "JOURNAL_ARTICLE_DELETE_SUCCESS",

  journalListFetchRequest: "JOURNAL_LIST_FETCH_REQUEST",
  journalListFetchSuccess: "JOURNAL_LIST_FETCH_SUCCESS"
};

// Journal Article Fetch

export function journalArticleFetch(id) {
  return {
    type: actionTypes.journalArticleFetchRequest,
    payload: { id }
  };
}

function* journalArticleFetchSaga() {
  yield takeLatest(actionTypes.journalArticleFetchRequest, function*(action) {
    try {
      const response = yield call(client.query,
        {
          query: gql`
            query($id: ID!) {
              journalArticle(id: $id) {
                id
                title
                subtitle
                content
                contentPlain
                excerpt
                createdAt
                updatedAt
                slug
                viewType
              }
            }
          `,
          variables: {
            id: action.payload.id
          },
      });

      yield put({
        type: actionTypes.journalArticleFetchSuccess,
        payload: {
          data: response.data
        }
      });
    } catch (e) {
      console.log(e);
    }
  });
}

// Change Tab

export function journalEditorChangeTab(newTabId) {
  return {
    type: actionTypes.journalEditorChangeTab,
    payload: {
      newTabId
    }
  };
}

// Set New Article

export function journalEditorSetNewArticle() {
  return {
    type: actionTypes.journalEditorSetNewArticle,
    payload: {
      article: {
        id: null,
        title: "",
        subtitle: "",
        content: "",
        contentPlain: "",
        createdAt: new Date(),
        updatedAt: new Date(),
        viewType: "normal",
        createdAtChange: false,
        updatedAtChange: false,
      }
    }
  };
}

// Journal Article Save

export function journalArticleCreate(values, history) {
  return {
    type: actionTypes.journalArticleCreateRequest,
    payload: {
      article: values,
      history: history // react-router history
    }
  };
}

function* journalArticleCreateSaga() {
  yield takeLatest(actionTypes.journalArticleCreateRequest, function*(action) {
    const article = {
      ...action.payload.article
    };

    if (!article.createdAtChange) {
      delete article.createdAt;
    }

    if (!article.updatedAtChange) {
      delete article.updatedAt;
    }

    delete article.createdAtChange;
    delete article.updatedAtChange;
    delete article.__typename;

    const history = action.payload.history;
    try {
      const response = yield call(async (article, history) => {
        const res = await client.mutate(
          {
            mutation: gql`
            mutation($journalArticle: JournalArticleInput!) {
              journalArticleCreate(journalArticle: $journalArticle) {
                journalArticle {
                  id
                  title
                  subtitle
                  content
                  contentPlain
                  excerpt
                  createdAt
                  updatedAt
                  slug
                  viewType
                }
              }
            }
          `,
            variables: {
              journalArticle: article,
            },
          });
          history.push(`/journal/${res.data.journalArticleCreate.journalArticle.id}`);
          return res;
        },
        article,
        history);

      yield put({
        type: actionTypes.journalArticleCreateSuccess,
        payload: {
          data: response.data
        }
      });
    } catch (e) {
      alert('Error')
      console.log(e)
    }
  });
}

// Journal Article Save

export function journalArticleSave(values) {
  console.log("values", values);
  return {
    type: actionTypes.journalArticleSaveRequest,
    payload: {
      article: values
    }
  };
}

function* journalArticleSaveSaga() {
  yield takeLatest(actionTypes.journalArticleSaveRequest, function*(action) {
    const article = {
      ...action.payload.article
    };

    if (!article.createdAtChange) {
      delete article.createdAt;
    }

    if (!article.updatedAtChange) {
      delete article.updatedAt;
    }

    delete article.createdAtChange;
    delete article.updatedAtChange;
    delete article.__typename;

    try {
      const response = yield call(client.mutate,
        {
          mutation: gql`
              mutation($journalArticle: JournalArticleInput!) {
                journalArticleUpdate(journalArticle: $journalArticle) {
                  journalArticle {
                    id
                    title
                    subtitle
                    content
                    contentPlain
                    excerpt
                    createdAt
                    updatedAt
                    slug
                    viewType
                  }
                }
              }
            `,
          variables: {
            journalArticle: article,
          },
        }
      );
      yield put({
        type: actionTypes.journalArticleSaveSuccess,
        payload: {
          data: response.data
        }
      });
    } catch (e) {
      console.log(e);
    }
  });
}

// JournalListFetch

export function journalListFetch() {
  return {
    type: actionTypes.journalListFetchRequest,
    payload: {}
  };
}

function* journalListFetchSaga() {
  yield takeLatest(actionTypes.journalListFetchRequest, function*(action) {
    try {
      const response = yield call(client.query,
        {
          query: gql`
            query {
              journalArticles(limit: 500) {
                id
                title
                subtitle
                slug
              }
            }
          `
        });
      yield put({
        type: actionTypes.journalListFetchSuccess,
        payload: {
          data: response.data
        }
      });
    } catch (e) {
      console.log(e);
    }
  });
}

// Delete

export function journalArticleDelete(id, history) {
  return {
    type: actionTypes.journalArticleDeleteRequest,
    payload: {
      id,
      history
    }
  };
}

function* journalArticleDeleteSaga() {
  yield takeLatest(actionTypes.journalArticleDeleteRequest, function*(action) {
    const { id, history } = action.payload;
    try {
      const response = yield call(async (id, history) => {
          const res = await client.mutate(
            {
              mutation: gql`
              mutation($id: ID!) {
                journalArticleDelete(id: $id) {
                  success
                }
              }
            `,
              variables: {
                id,
              },
            });
          history.push(`/journal/`);
          return res;
        },
        id,
        history);
      yield put({
        type: actionTypes.journalArticleDeleteSuccess,
        payload: {
          data: response.data
        }
      });
    } catch (e) {
      console.log(e);
    }
  });
}

// Edited content

export function journalEditorEditedContent(id) {
  return {
    type: actionTypes.journalEditorEditedContent,
    payload: { id }
  };
}

// End session

export function journalEditorEndSession(id) {
  return {
    type: actionTypes.journalEditorEndSession,
    payload: {}
  };
}

// Return array of sagas
export const sagas = [
  fork(journalArticleFetchSaga),
  fork(journalListFetchSaga),
  fork(journalArticleSaveSaga),
  fork(journalArticleCreateSaga),
  fork(journalArticleDeleteSaga)
];
