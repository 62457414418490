import { actionTypes } from "./actions_sagas";

const defaultState = {
  signedIn: false,
  loading: true
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.authSignIn:
      return {
        signedIn: true,
        loading: false
      };
    case actionTypes.authLoadRequest:
      return {
        ...state,
        loading: true
      };
    case actionTypes.authLoadSuccess:
      return {
        signedIn: action.payload.data.signedIn,
        loading: false
      };
    default:
      return state;
  }
}
