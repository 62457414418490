import React from "react";
import { Spinner } from "@blueprintjs/core";
import { Link } from "react-router-dom";

export default class IndexTiles extends React.Component {
  renderItems() {
    let items = this.props.items.map(item => {
      return (
        <Link
          to={`/${this.props.resource}/${item.id}`}
          key={item.id}
          className="index-tiles-item"
        >
          <div className="index-tiles-item-inner">
            <div className="index-tiles-item-title">{item.title}</div>
            <div className="index-tiles-item-subtitle">{item.subtitle}</div>
          </div>
        </Link>
      );
    });

    let newItem = (
      <Link
        to={`/${this.props.resource}/new`}
        key="item-new"
        className="index-tiles-item index-tiles-item-new"
      >
        <div className="index-tiles-item-inner">
          <div className="index-tiles-item-new-text">Create</div>
        </div>
      </Link>
    );

    return [newItem, ...items];
  }
  render() {
    return (
      <div className="index-tiles-container clearfix">
        {!this.props.items && (
          <div className="index-tiles-loading">
            <Spinner />
          </div>
        )}
        {this.props.items && this.renderItems()}
      </div>
    );
  }
}
