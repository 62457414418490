import React from "react";

import NotesIndex from "./NotesIndex";
import NotesEditor from "./NotesEditor";
import { Route, Switch } from "react-router-dom";

import "./styles/notes.css";

export default class Notes extends React.Component {
  render() {
    return (
      <div className="notes">
        <Switch>
          <Route
            exact
            path={`${this.props.match.url}`}
            component={NotesIndex}
          />
          <Route path={`${this.props.match.url}/new`} component={NotesEditor} />
          <Route path={`${this.props.match.url}/:id`} component={NotesEditor} />
        </Switch>
      </div>
    );
  }
}
