import React from "react";
import { connect } from "react-redux";

import { journalListFetch } from "./actions_sagas";
import IndexTiles from "../../common/IndexTiles";

class JournalIndex extends React.Component {
  componentDidMount() {
    this.props.journalListFetch();
  }

  render() {
    return <IndexTiles items={this.props.articles} resource={"journal"} />;
  }
}

function mapStateToProps({ journal }) {
  return {
    articles: journal.journalList
  };
}

export default connect(mapStateToProps, { journalListFetch })(JournalIndex);
