import React from "react";
import { connect } from "react-redux";

import { notesListFetch } from "./actions_sagas";
import IndexTiles from "../../common/IndexTiles";

class NotesIndex extends React.Component {
  componentDidMount() {
    this.props.notesListFetch();
  }

  render() {
    return <IndexTiles items={this.props.notes} resource={"notes"} />;
  }
}

function mapStateToProps({ notes }) {
  return {
    notes: notes.notesList
  };
}

export default connect(mapStateToProps, { notesListFetch })(NotesIndex);
