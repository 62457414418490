import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import JournalReducer from "../modules/journal/reducer";
import NotesReducer from "../modules/notes/reducer";
import AuthReducer from "../modules/auth/reducer";

const rootReducer = combineReducers({
  journal: JournalReducer,
  notes: NotesReducer,
  auth: AuthReducer,
  form: formReducer
});

export default rootReducer;
