import { call, put, fork, takeLatest } from "redux-saga/effects";
import axios from "axios";
import client from "../../common/apollo";
import {gql} from 'apollo-boost';

export const actionTypes = {
  notesListFetchRequest: "NOTES_LIST_FETCH_REQUEST",
  notesListFetchSuccess: "NOTES_LIST_FETCH_SUCCESS",

  noteFetchRequest: "NOTE_FETCH_REQUEST",
  noteFetchSuccess: "NOTE_FETCH_SUCCESS",

  notesEditorSetNewNote: "NOTES_EDITOR_SET_NEW_NOTE",

  notesEditorEndSession: "NOTES_EDITOR_END_SESSION",

  noteSaveRequest: "NOTE_SAVE_REQUEST",
  noteSaveSuccess: "NOTE_SAVE_SUCCESS",

  noteCreateRequest: "NOTE_CREATE_REQUEST",
  noteCreateSuccess: "NOTE_CREATE_SUCCESS",

  noteDeleteRequest: "NOTE_DELETE_REQUEST",
  noteDeleteSuccess: "NOTE_DELETE_SUCCESS",

  noteEditorEditedContent: "NOTE_EDITOR_EDITED_CONTENT"
};

// Notes List Fetch

export function notesListFetch() {
  return {
    type: actionTypes.notesListFetchRequest,
    payload: {}
  };
}

function* notesListFetchSaga() {
  yield takeLatest(actionTypes.notesListFetchRequest, function*(action) {
    try {
      const response = yield call(client.query,
        {
          query: gql`
            query {
              notes {
                id
                title
                subtitle
                slug
              }
            }
          `
        });
      yield put({
        type: actionTypes.notesListFetchSuccess,
        payload: {
          data: response.data
        }
      });
    } catch (e) {
      console.log(e);
    }
  });
}

export function noteFetch(id) {
  return {
    type: actionTypes.noteFetchRequest,
    payload: { id }
  };
}

function* noteFetchSaga() {
  yield takeLatest(actionTypes.noteFetchRequest, function*(action) {
    try {
      const response = yield call(client.query,
        {
          query: gql`
            query($id: ID!) {
              note(id: $id) {
                id
                title
                subtitle
                content
                createdAt
                slug
              }
            }
          `,
          variables: {
            id: action.payload.id
          },
        });
      yield put({
        type: actionTypes.noteFetchSuccess,
        payload: {
          data: response.data
        }
      });
    } catch (e) {
      console.log(e);
    }
  });
}

export function notesEditorSetNewNote() {
  return {
    type: actionTypes.notesEditorSetNewNote,
    payload: {
      note: {
        id: null,
        title: "",
        subtitle: "",
        slug: "",
        content: "",
        createdAt: new Date()
      }
    }
  };
}

export function notesEditorEndSession() {
  return {
    type: actionTypes.notesEditorEndSession,
    payload: {}
  };
}

// Only used to submit the Redux-Form remotely.
export function notesEditorSaveSubmit() {
  return {
    type: "@@redux-form/SUBMIT",
    meta: { form: "notesEditorForm" }
  };
}

// Note save
export function noteSave(values) {
  return {
    type: actionTypes.noteSaveRequest,
    payload: {
      note: values
    }
  };
}

function* noteSaveSaga() {
  yield takeLatest(actionTypes.noteSaveRequest, function*(action) {
    const note = {
      ...action.payload.note
    };

    if (!note.createdAtChange) {
      delete note.createdAt;
    }

    delete note.createdAtChange;
    delete note.__typename;
    console.log(note);

    try {
      const response = yield call(client.mutate,
        {
          mutation: gql`
              mutation($note: NoteInput!) {
                noteUpdate(note: $note) {
                  note {
                    id
                    title
                    subtitle
                    content
                    createdAt
                    slug
                  }
                }
              }
            `,
          variables: {
            note,
          },
        }
      );

      yield put({
        type: actionTypes.noteSaveSuccess,
        payload: {
          data: response.data
        }
      });
    } catch (e) {
      console.log(e);
    }
  });
}

// Note create

export function noteCreate(values, history) {
  return {
    type: actionTypes.noteCreateRequest,
    payload: {
      note: values,
      history: history // react-router history
    }
  };
}

function* noteCreateSaga() {
  yield takeLatest(actionTypes.noteCreateRequest, function*(action) {
    const note = {
      ...action.payload.note
    };

    if (!note.createdAtChange) {
      delete note.createdAt;
    }

    delete note.createdAtChange;
    delete note.__typename;

    const history = action.payload.history;

    try {
      const response = yield call(async (note, history) => {
        const res = await client.mutate(
          {
            mutation: gql`
              mutation($note: NoteInput!) {
                noteCreate(note: $note) {
                  note {
                    id
                    subtitle
                    content
                    createdAt
                    slug
                  }
                }
              }
            `,
            variables: {
              note: note,
            },
          });
          history.push(`/notes/${res.data.noteCreate.note.id}`);
          return res;
        },
        note,
        history);

      yield put({
        type: actionTypes.noteCreateSuccess,
        payload: {
          data: response.data
        }
      });
    } catch (e) {
      console.log(e);
    }
  });
}

// Note delete

export function noteDelete(id, history) {
  return {
    type: actionTypes.noteDeleteRequest,
    payload: {
      id,
      history
    }
  };
}

function* noteDeleteSaga() {
  yield takeLatest(actionTypes.noteDeleteRequest, function*(action) {
    const { id, history } = action.payload;
    try {
      const response = yield call(async (id, history) => {
        const res = await client.mutate(
          {
            mutation: gql`
              mutation($id: ID!) {
                noteDelete(id: $id) {
                  success
                }
              }
            `,
            variables: {
              id,
            },
          });
          history.push(`/notes/`);
          return res;
        },
        id,
        history);
      yield put({
        type: actionTypes.noteDeleteSuccess,
        payload: {
          data: response.data
        }
      });
    } catch (e) {
      console.log(e);
    }
  });
}

export function noteEditorEditedContent() {
  return {
    type: actionTypes.noteEditorEditedContent,
    payload: {}
  };
}

export const sagas = [
  fork(notesListFetchSaga),
  fork(noteFetchSaga),
  fork(noteSaveSaga),
  fork(noteCreateSaga),
  fork(noteDeleteSaga)
];
