import React from "react";
import "./styles/auth.css";
import { Icon } from "@blueprintjs/core";

class Auth extends React.Component {
  render() {
    let csrfToken = null;
    const csrfTag = document.querySelector('meta[name="csrf-token"]');
    if (csrfTag) {
      csrfToken = csrfTag.getAttribute("content");
    }
    return (
      <div className="auth-container">
        <div className="pt-card">
          <div className="auth-header">
            Easel<span>CMS</span>
          </div>
          <div className="auth-form">
            <div className="pt-control-group pt-vertical">
              <form action="/users/sign_in" method="POST">
                <input type="hidden" name="authenticity_token" value={csrfToken} />
                <div className="pt-input-group pt-large">
                  <Icon icon="person" />
                  <input
                    name="user[email]"
                    type="text"
                    className="pt-input"
                    placeholder="Email"
                  />
                </div>
                <div className="pt-input-group pt-large">
                  <Icon icon="lock" />
                  <input
                    name="user[password]"
                    type="password"
                    className="pt-input"
                    placeholder="Password"
                  />
                </div>
                <button
                  type="submit"
                  className="pt-button pt-fill pt-large pt-intent-primary"
                >
                  Login
                </button>
                <label className="pt-control pt-checkbox" style={{marginTop: '10px'}}><input name="user[remember_me]" type="hidden" value="0" /><input type="checkbox" value="1" name="user[remember_me]" id="user_remember_me" /><span class="pt-control-indicator"></span> Remember</label>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Auth;
