import { call, put, fork, takeLatest } from "redux-saga/effects";
import axios from "axios";

export const actionTypes = {
  authSignIn: "AUTH_SIGN_IN",
  authLoadRequest: "AUTH_LOAD_REQUEST",
  authLoadSuccess: "AUTH_LOAD_SUCCESS"
};

export function authLoad() {
  return {
    type: "AUTH_LOAD_REQUEST",
    payload: {}
  };
}

function* authLoadSaga() {
  yield takeLatest(actionTypes.authLoadRequest, function*(action) {
    console.log('taking')
    try {
      const url = `/auth`;
      const response = yield call(axios.post, url);
      yield put({
        type: actionTypes.authLoadSuccess,
        payload: {
          data: response.data
        }
      });
    } catch (e) {
      console.log(e);
    }
  });
}

export const sagas = [fork(authLoadSaga)];
