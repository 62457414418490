import { actionTypes } from "./actions_sagas";

const defaultState = {
  journalList: null,
  editingArticle: null,
  editingArticleTab: "content",
  editingArticleSaveActivity: false,
  editingArticleHasUnsavedChanges: false
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.journalArticleFetchRequest:
      // Loading state
      return {
        ...state,
        editingArticle: null,
        editingArticleTab: "content",
        editingArticleSaveActivity: false,
        editingArticleHasUnsavedChanges: false
      };
    case actionTypes.journalArticleFetchSuccess:
      return {
        ...state,
        editingArticle: {
          ...action.payload.data.journalArticle,
          createdAt: new Date(action.payload.data.journalArticle.createdAt),
          updatedAt: new Date(action.payload.data.journalArticle.updatedAt),
          createdAtChange: false,
          updatedAtChange: false,
        }
      };
    case actionTypes.journalEditorChangeTab:
      return {
        ...state,
        editingArticleTab: action.payload.newTabId
      };
    case actionTypes.journalEditorSetNewArticle:
      return {
        ...state,
        editingArticle: action.payload.article
      };
    case actionTypes.journalListFetchSuccess:
      return {
        ...state,
        journalList: action.payload.data.journalArticles
      };
    case actionTypes.journalArticleSaveRequest:
      return {
        ...state,
        editingArticleSaveActivity: true
      };
    case actionTypes.journalArticleCreateRequest:
      return {
        ...state,
        editingArticleSaveActivity: false, // hack
        editingArticleHasUnsavedChanges: false
      };
    case actionTypes.journalArticleSaveSuccess:
      return {
        ...state,
        editingArticleSaveActivity: false,
        editingArticleHasUnsavedChanges: false
      };
    case actionTypes.journalEditorEditedContent:
      return {
        ...state,
        editingArticleHasUnsavedChanges: true
      };
    case actionTypes.journalEditorEndSession:
      return {
        ...state,
        editingArticle: null,
        editingArticleTab: "content",
        editingArticleSaveActivity: false,
        editingArticleHasUnsavedChanges: false
      };
    default:
      return state;
  }
}
